import React from 'react';
import { Link } from 'gatsby';
import paginate from 'jw-paginate';

const ActivePage = ({ n }) => (
    <Link
        to={`/${n === 1 ? '' : n}`}
        className="inline-flex items-center px-4 pt-4 text-sm font-medium text-indigo-600 border-t-2 border-indigo-500"
    >
        {n}
    </Link>
);

const InactivePage = ({ n }) => (
    <Link
        to={`/${n === 1 ? '' : n}`}
        className="inline-flex items-center px-4 pt-4 text-sm text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300 font-mediu"
        aria-current="page"
    >
        {n}
    </Link>
);

const PageNav = ({ of, page, limit, items }) => {
    const { pages } = paginate(items, page, limit, 5);

    return (
        <nav className="flex items-center justify-between m-4 border-t border-gray-200 sm:px-0">
            <div className="flex flex-1 w-0 -mt-px">
                {page > 1 && (
                    <Link
                        to={`/${page - 1 > 1 ? page - 1 : ''}`}
                        className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
                    >
                        <svg
                            className="w-5 h-5 mr-3 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                        Previous
                    </Link>
                )}
            </div>
            <div className="hidden md:-mt-px md:flex">
                {page === 1 ? <ActivePage n={1} /> : <InactivePage n={1} />}
                {!pages.includes(2) && (
                    <span className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
                        ...
                    </span>
                )}
                {pages
                    .filter(p => p !== 1 && p !== of)
                    .map(p =>
                        p === page ? (
                            <ActivePage n={p} />
                        ) : (
                            <InactivePage n={p} />
                        )
                    )}
                {!pages.includes(of - 1) && (
                    <span className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent">
                        ...
                    </span>
                )}
                {page === of ? <ActivePage n={of} /> : <InactivePage n={of} />}
            </div>
            <div className="flex justify-end flex-1 w-0 -mt-px">
                {page !== of && (
                    <Link
                        to={`/${page + 1}`}
                        className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300"
                    >
                        Next
                        <svg
                            className="w-5 h-5 ml-3 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Link>
                )}
            </div>
        </nav>
    );
};

export default PageNav;
