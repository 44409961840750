import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TextHeader from '../components/TextHeader';
import VideoCard from '../components/VideoCard';
import PageNav from '../components/PageNav';

const SiteIndex = ({ pageContext, data }) => {
    const { currentPage, pageCount, totalItems, limit } = pageContext;
    const { title, description } = data.site.siteMetadata;

    const videos = data.allVideosJson.nodes;
    const models = data.allModelsJson.nodes;

    return (
        <Layout {...data.site.siteMetadata}>
            <Seo
                pageTitle="All Videos"
                description="View all FemScat videos here, on KinkyScat.com!"
                image={videos[0].preview}
                {...data.site.siteMetadata}
            />
            <TextHeader title={title} subtitle={description} />
            <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:max-w-none">
                {videos.map(video => {
                    const model = models.find(m => m.storeId === video.storeId);

                    // Some videos do not have a model, as that model has been removed from the site
                    if (!model) {
                        return undefined;
                    }

                    return (
                        <VideoCard
                            key={`${video.storeId}+${video.videoId}`}
                            video={video}
                            model={model}
                        />
                    );
                })}
            </div>
            <PageNav
                page={currentPage}
                of={pageCount}
                items={totalItems}
                limit={limit}
            />
        </Layout>
    );
};

export default SiteIndex;

export const pageQuery = graphql`
    query ListQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
                description
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
        allCategoriesJson(sort: { fields: categoryId, order: DESC }) {
            nodes {
                categoryId
                categoryName
            }
        }
        allModelsJson {
            nodes {
                storeId
                name
                store
                avatar
            }
        }
        allVideosJson(
            sort: { fields: date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                storeId
                videoId
                title
                description
                preview
                categories {
                    categoryId
                    categoryName
                }
                link
                date
            }
        }
    }
`;
